cds-input[required="true"] label::after {
  content: '*';
  color: #f56600;
  display: inline;
  margin-left: 2px;
  font-weight: 700;
}

span.required {
  color: #f56600;
  font-weight: 700;
}
