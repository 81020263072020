/* @import '~@cds/core/styles/theme.dark.min.css'; */

footer {
  background-color: var(--clr-header-bg-color);
  color: var(--cds-global-color-construction-200);
}

footer p[cds-text*=secondary] {
  color: var(--cds-global-color-construction-200);
}

